<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="column is-4 is-offset-4">
          <div class="box">
            <form @submit.prevent="signup">
              <div class="field">
                <div class="control">
                  <input
                    class="input is-large"
                    type="email"
                    placeholder="Your Email"
                    v-model="email"
                  />
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <input
                    class="input is-large"
                    type="password"
                    placeholder="Your Password"
                    v-model="password"
                  />
                </div>
              </div>

              <button
                class="button is-block is-info is-large is-fullwidth"
                type="submit"
              >
                Login <i class="fa fa-sign-in-alt" aria-hidden="true"></i>
              </button>
            </form>
          </div>
          <p class="has-text-grey">
            <a href="#" class="btn" @click="google"> Login with Google </a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { watch, defineComponent } from 'vue'
import { user, google, useSignup } from '../components/firebase.js'
import router from '@/router'
export default defineComponent({
  props: {
    loginReturnUrl: { type: String, default: '/' }
  },
  setup (props) {
    watch(
      () => user.value,
      (newUser) => {
        if (newUser) {
          router.push(props.loginReturnUrl)
        }
      }
    )
    return {
      ...useSignup(),
      google
    }
  }
})
</script>
